/* eslint-disable max-lines-per-function */
import {
    CustomIcon,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ToggleCollapseContainer,
    Toolbar,
    Typography,
} from '@deltasierra/react/components/core';
import Link from 'next/link';
import { Navigation } from '../types';

export type LeftNavigationLeftDrawerProps = {
    isOpenDrawer: boolean;
    navigation: Navigation;
};

export const MAX_NAVIGATION_WIDTH = '12rem';
export const MIN_NAVIGATION_WIDTH = '4.25rem';

export const LeftNavigationLeftDrawer = ({ isOpenDrawer, navigation }: LeftNavigationLeftDrawerProps): JSX.Element => (
    <Drawer
        open={isOpenDrawer}
        sx={{
            '& .MuiDrawer-paper': {
                borderWidth: 0,
                overflowX: 'hidden',
                width: isOpenDrawer ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH,
            },
            overflowX: 'hidden',
            width: isOpenDrawer ? MAX_NAVIGATION_WIDTH : MIN_NAVIGATION_WIDTH,
        }}
        variant="permanent"
    >
        <Toolbar />
        {navigation.map(
            section =>
                (isOpenDrawer || section.isMaintainedOnClose) && (
                    <>
                        {isOpenDrawer && section.hasDividerAbove && <Divider />}
                        <List
                            key={section.id}
                            sx={[
                                {
                                    '& .MuiListItem-root': isOpenDrawer
                                        ? {}
                                        : {
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                              marginBottom: '0.5rem',
                                          },
                                    '& .MuiListItemButton-root': {
                                        '&:hover': {
                                            backgroundColor: 'primary.light',
                                        },
                                        borderRadius: '8px',
                                        ...isOpenDrawer
                                            ? { marginX: '0.5rem' }
                                            : {
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  marginX: '0.2rem',
                                                  marginY: 0,
                                                  paddingX: 0,
                                              },
                                    },
                                },
                                isOpenDrawer && {
                                    '& .MuiListItemIcon-root': {
                                        marginLeft: '-2px',
                                        marginRight: '1.25rem',
                                    },
                                },
                            ]}
                        >
                            <>
                                {isOpenDrawer && section.headerTitle && (
                                    <ListItem>
                                        <Typography variant="h5">{section.headerTitle}</Typography>
                                    </ListItem>
                                )}
                                {section.items.map(item => {
                                    const showSubItems = isOpenDrawer && item.subItems && item.subItems.length > 0;
                                    return (
                                        <ListItem
                                            disablePadding
                                            key={item.id}
                                            sx={[
                                                item.isSelected
                                                    ? {
                                                          '& .MuiListItemIcon-root, .MuiListItemText-root': {
                                                              color: 'primary.main',
                                                          },
                                                      }
                                                    : {},
                                            ]}
                                        >
                                            <ToggleCollapseContainer
                                                isDisabled={!showSubItems}
                                                title={
                                                    <ListItemButton
                                                        LinkComponent={Link}
                                                        color="primary"
                                                        disabled={item.isDisabled}
                                                        href={item.navigationUrl}
                                                        sx={[
                                                            {
                                                                minHeight: 48,
                                                            },
                                                        ]}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                minWidth: 0,
                                                            }}
                                                        >
                                                            <CustomIcon customIconName={item.icon} />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={item.title}
                                                            primaryTypographyProps={{
                                                                fontSize: isOpenDrawer ? undefined : '0.625rem',
                                                                lineHeight: 'normal',
                                                                textAlign: isOpenDrawer ? undefined : 'center',
                                                                variant: isOpenDrawer ? 'body1' : 'body2',
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                }
                                            >
                                                {showSubItems && (
                                                    <List disablePadding>
                                                        {item.subItems?.map(subItem => (
                                                            <ListItem
                                                                disablePadding
                                                                key={subItem.id}
                                                                sx={[
                                                                    { padding: 0 },
                                                                    subItem.isSelected
                                                                        ? {
                                                                              '& .MuiListItemText-root': {
                                                                                  color: 'primary.main',
                                                                              },
                                                                          }
                                                                        : {},
                                                                ]}
                                                            >
                                                                <ListItemButton
                                                                    LinkComponent={Link}
                                                                    color="primary"
                                                                    disabled={subItem.isDisabled}
                                                                    href={subItem.navigationUrl}
                                                                    key={subItem.id}
                                                                    sx={{
                                                                        paddingLeft: '3.75rem',
                                                                    }}
                                                                >
                                                                    <ListItemText
                                                                        primary={subItem.title}
                                                                        primaryTypographyProps={{
                                                                            variant: 'subtitle2',
                                                                        }}
                                                                    />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                )}
                                            </ToggleCollapseContainer>
                                        </ListItem>
                                    );
                                })}
                            </>
                        </List>
                    </>
                ),
        )}
    </Drawer>
);

LeftNavigationLeftDrawer.displayName = 'LeftNavigationLeftDrawer';
