import { Draft } from 'immer';
import { AssetLibraryBrowseState, ActionSelectCollection } from '../../types';

export function handleActionSelectCollection(
    state: Draft<AssetLibraryBrowseState>,
    action: ActionSelectCollection,
): void {
    state.activeCollectionId = action.collectionId;
    state.currentSelectedFolder = {
        collectionId: action.collectionId,
        type: 'collection',
    };
    state.folderHistory = [];
}
