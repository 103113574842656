import Link from 'next/link';
import {
    Alert,
    AlertTitle,
    Badge,
    Box,
    Button,
    Collapse,
    CustomIcon,
    IconButton,
    Popover,
    Stack,
    Typography,
} from '@deltasierra/react/components/core';
import { TransitionGroup } from 'react-transition-group';
import { Bell01, ChevronRight } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { DateTime } from 'luxon';
import { MouseEvent, useMemo } from 'react';
import { Notification } from '../types';

const MAX_NOTIFICATIONS = 9;

export type LeftNavigationTopBarNotificationsProps = {
    anchorEl: HTMLElement | null;
    isLoadingNotifications: boolean;
    notificationUrl: string;
    notifications: Notification[];
    onClickClose: () => void;
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
    onClickOpen: (event: MouseEvent<HTMLElement>) => void;
    open: boolean;
    t: Translations<'Navigation'>;
};

// eslint-disable-next-line max-lines-per-function
export const LeftNavigationTopBarNotifications = ({
    anchorEl,
    isLoadingNotifications,
    notifications,
    notificationUrl,
    onClickClose,
    onClickDismissAllNotifications,
    onClickDismissNotification,
    onClickOpen,
    open,
    t,
}: LeftNavigationTopBarNotificationsProps): JSX.Element => {
    const numOfNotifications = useMemo(() => notifications.length, [notifications]);
    return (
        <>
            <IconButton
                aria-label={t('Notifications')}
                disabled={isLoadingNotifications}
                sx={{ color: 'primary.main' }}
                onClick={onClickOpen}
            >
                <Badge
                    color="error"
                    invisible={numOfNotifications === 0}
                    overlap="circular"
                    sx={{
                        '& .MuiBadge-badge': {
                            right: 8,
                            top: 5,
                        },
                    }}
                    variant="dot"
                >
                    <Bell01 />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                id="notifications-popover"
                open={open}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                onClose={onClickClose}
            >
                <Stack
                    sx={{
                        padding: '1rem',
                        paddingTop: '0.25rem',
                        rowGap: '0.5rem',
                        userSelect: 'none',
                        width: '30rem',
                    }}
                >
                    <Stack alignItems="center" direction="row" justifyContent="space-between">
                        <Box
                            sx={{
                                alignItems: 'center',
                                columnGap: '0.5rem',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography variant="h5">{t('Notifications')}</Typography>
                            {numOfNotifications > 0 && (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        backgroundColor: 'error.main',
                                        borderRadius: '100%',
                                        display: 'flex',
                                        height: '1.5rem',
                                        justifyContent: 'center',
                                        width: '1.5rem',
                                    }}
                                >
                                    <Typography sx={{ color: 'common.white' }} variant="h6">
                                        {numOfNotifications > MAX_NOTIFICATIONS
                                            ? `${MAX_NOTIFICATIONS}+`
                                            : numOfNotifications}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Button
                            disabled={numOfNotifications === 0}
                            sx={{ paddingY: '1rem' }}
                            onClick={onClickDismissAllNotifications}
                        >
                            {t('Mark all as read')}
                        </Button>
                    </Stack>
                    <Stack
                        sx={{
                            maxHeight: '25rem',
                            overflowY: 'auto',
                        }}
                    >
                        <TransitionGroup
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '0.5rem',
                            }}
                        >
                            {notifications.map(notification => {
                                const linkProps = notification.link
                                    ? {
                                          component: Link,
                                          href: notification.link,
                                      }
                                    : {};
                                return (
                                    <Collapse key={notification.id}>
                                        <Box {...linkProps} sx={{ textDecoration: 'none' }}>
                                            <Alert
                                                icon={
                                                    notification.icon && (
                                                        <CustomIcon customIconName={notification.icon} />
                                                    )
                                                }
                                                severity="info"
                                                onClose={event => {
                                                    onClickDismissNotification(notification.id);
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }}
                                            >
                                                <AlertTitle>{notification.title}</AlertTitle>
                                                {notification.timestamp?.toLocaleString(DateTime.DATETIME_MED)}
                                            </Alert>
                                        </Box>
                                    </Collapse>
                                );
                            })}
                        </TransitionGroup>
                    </Stack>
                    {notifications.length === 0 && (
                        <Typography
                            sx={{
                                color: 'grey.600',
                                textAlign: 'center',
                            }}
                            variant="h6"
                        >
                            {t('You have no unread notifications')}
                        </Typography>
                    )}
                    <Button LinkComponent={Link} href={notificationUrl}>
                        <Typography>{t('View all notifications')}</Typography>
                        <ChevronRight style={{ fontSize: 'inherit' }} />
                    </Button>
                </Stack>
            </Popover>
        </>
    );
};

LeftNavigationTopBarNotifications.displayName = 'LeftNavigationTopBarNotifications';
