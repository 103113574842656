import { graphql } from '../../__graphqlTypes';

export const LOCATION_FEATURES_FRAGMENT = graphql(`
    fragment LocationFeaturesFragment on LocationFeaturesNode {
        dashboardV2
        enableCropResizeAssetLibraryV2
        permitDownloadEmailPublish
        showOldEmailTemplatesOption
        socialMergeFieldsFrontend
        templateGalleryV2
        enableNewGalleryPage
    }
`);
