import { Draft } from 'immer';
import { AssetLibraryBrowseState, ActionSelectFolder } from '../../types';

export function handleActionSelectFolder(state: Draft<AssetLibraryBrowseState>, action: ActionSelectFolder): void {
    state.folderHistory.push(action.folder);
    state.currentSelectedFolder = {
        folderId: action.folder.id,
        type: 'folder',
    };
}
