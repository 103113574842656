import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

export type AssetEditActionsProps = {
    t: Translations<'AssetEdit'>;
};

export const AssetEditActions = (_props: AssetEditActionsProps): JSX.Element => (
    <Box>
        {/* TODO: Actions - DSL-4910 */}
        {'Actions'}
    </Box>
);

AssetEditActions.displayName = 'AssetEditActions';
