'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import { GET_LEGACY_LOCATION } from '@deltasierra/frontend/graphql';
import { getCookie } from 'cookies-next';
import { useEffect, useMemo } from 'react';

const COOKIE_KEY = 'plannerLocation';

// eslint-disable-next-line jsdoc/require-jsdoc
export function useUserAppContext(redirectUrl: string): {
    clientId: string | null;
    locationId: string | null;
} {
    const legacyLocationIdCookie = getCookie(COOKIE_KEY);
    const legacyLocationId = useMemo(() => {
        try {
            const id = legacyLocationIdCookie ? parseInt(legacyLocationIdCookie, 10) : null;
            return Number.isNaN(id) ? null : id;
        } catch {
            return null;
        }
    }, [legacyLocationIdCookie]);

    const { data } = useSuspenseQuery(
        GET_LEGACY_LOCATION,
        legacyLocationId
            ? {
                  variables: { legacyLocationId },
              }
            : skipToken,
    );
    const userData = useMemo(() => {
        const contexts = data?.legacyLocation;
        if (contexts) {
            return {
                clientId: contexts.client.id,
                locationId: contexts.id,
            };
        } else {
            return { clientId: null, locationId: null };
        }
    }, [data]);
    useEffect(() => {
        if (legacyLocationId && userData.locationId === null) {
            window.location.replace(redirectUrl);
        }
    }, [legacyLocationId, redirectUrl, userData.locationId]);
    return userData;
}
