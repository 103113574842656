import { graphql } from '../../__graphqlTypes';

export const GET_USER_FEATURE_FLAGS = graphql(`
    query GetUserFeatureFlags {
        me {
            id
            features {
                ...UserFeaturesFragment
            }
        }
    }
`);
