/* eslint-disable @typescript-eslint/no-extra-parens */
import { Breadcrumbs, Link, Skeleton, Typography } from '@deltasierra/react/components/core';
import { ChevronRight } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { Folder } from './types';

export type AssetLibraryBrowserGridBreadcrumbsProps = {
    collectionTitle?: string;
    currentFolders: Folder[];
    isLoadingCollections: boolean;
    onClickBreadcrumb: (id?: string) => void;
    t: Translations<'AssetLibrary'>;
};

const Breadcrumb = ({ folder: { id, title }, onClick }: { folder: Folder; onClick: () => void }) => (
    <Link color="inherit" key={id} sx={{ cursor: 'pointer', minWidth: '4rem' }} underline="hover" onClick={onClick}>
        <Typography variant="h3">{title}</Typography>
    </Link>
);
Breadcrumb.displayName = 'Breadcrumb';

export const AssetLibraryBrowserGridBreadcrumbs = ({
    collectionTitle,
    currentFolders,
    isLoadingCollections,
    onClickBreadcrumb,
    t,
}: AssetLibraryBrowserGridBreadcrumbsProps): JSX.Element => (
    <Breadcrumbs aria-label={t('Breadcrumbs')} maxItems={3} separator={<ChevronRight />}>
        {collectionTitle ? (
            <Breadcrumb
                folder={{
                    id: 'collection',
                    title: collectionTitle,
                }}
                onClick={() => onClickBreadcrumb()}
            />
        ) : (
            isLoadingCollections && <Skeleton height="2rem" width="5rem" />
        )}
        {currentFolders.map(folder => (
            <Breadcrumb folder={folder} key={folder.id} onClick={() => onClickBreadcrumb(folder.id)} />
        ))}
    </Breadcrumbs>
);

AssetLibraryBrowserGridBreadcrumbs.displayName = 'AssetLibraryBrowserGridBreadcrumbs';
