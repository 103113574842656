import { graphql } from '../../__graphqlTypes';

export const MARK_USER_NOTIFICATION_READ = graphql(`
    mutation MarkUserNotificationRead($input: MarkReadNotificationsInput!) {
        markReadNotification(input: $input) {
            ... on MarkReadSuccess {
                numberMarkedRead
            }
            ... on MarkReadError {
                code
                message
            }
        }
    }
`);
