'use client';

import { Box } from '@deltasierra/react/components/core';
import { ReactNode } from 'react';
import NavigationContainer from '../../../components/NavigationContainer';
import { NavigationStateContextProvider } from '../../../components/NavigationStateContextProvider';
import { GlobalDataContextProvider } from '../../../components/core/GlobalDataContextProvider';

/**
 * This is the layout component for all routes that require being authenticated.
 * For now all the routes require authentication so they are all nested under this layout.
 *
 * This currently provides the navigation and page layout too... however this should be moved to the
 * app layout when we need to share the components in future.
 */
export default function AppAuthLayout({ children }: { children: ReactNode }): JSX.Element {
    return (
        <GlobalDataContextProvider>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100svh',
                }}
            >
                <NavigationStateContextProvider>
                    <NavigationContainer>
                        <Box
                            component="main"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            {children}
                        </Box>
                    </NavigationContainer>
                </NavigationStateContextProvider>
            </Box>
        </GlobalDataContextProvider>
    );
}
AppAuthLayout.displayName = 'AppAuthLayout';
