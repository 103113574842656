import { Draft } from 'immer';
import { AssetLibraryBrowseState, ActionToggleCollectionList } from '../../types';

export function handleActionToggleCollectionList(
    state: Draft<AssetLibraryBrowseState>,
    action: ActionToggleCollectionList,
): void {
    if (typeof action.open === 'undefined') {
        action.modalCollectionIds.forEach(idToToggle => {
            if (state.openModalCollectionIds.includes(idToToggle)) {
                state.openModalCollectionIds = state.openModalCollectionIds.filter(id => id !== idToToggle);
            } else {
                state.openModalCollectionIds.push(idToToggle);
            }
        });
    } else if (action.open) {
        state.openModalCollectionIds.push(...action.modalCollectionIds);
    } else {
        state.openModalCollectionIds = state.openModalCollectionIds.filter(
            id => !action.modalCollectionIds.includes(id),
        );
    }
}
