import { useSuspenseQuery } from '@apollo/client';
import {
    CLIENT_FEATURES_FRAGMENT,
    GET_CLIENT_FEATURE_FLAGS,
    GetClientFeatureFlagsQuery,
    GetClientFeatureFlagsQueryVariables,
    getFragmentData,
} from '@deltasierra/frontend/graphql';
import { useMemo } from 'react';

export function useAssetLibraryFeatureFlag(clientId: string): {
    enableCropResizeAssetLibraryV2: boolean;
    isAssetLibraryV2Enabled: boolean;
} {
    const { data } = useSuspenseQuery<GetClientFeatureFlagsQuery, GetClientFeatureFlagsQueryVariables>(
        GET_CLIENT_FEATURE_FLAGS,
        {
            fetchPolicy: 'cache-and-network',
            variables: { clientId },
        },
    );

    const clientFeatures = getFragmentData(CLIENT_FEATURES_FRAGMENT, data.client?.features);
    const { enableCropResizeAssetLibraryV2, isAssetLibraryV2Enabled } = useMemo(
        () => ({
            enableCropResizeAssetLibraryV2: !!clientFeatures?.enableCropResizeAssetLibraryV2,
            isAssetLibraryV2Enabled: !!clientFeatures?.isAssetLibraryV2Enabled,
        }),
        [clientFeatures],
    );

    return { enableCropResizeAssetLibraryV2, isAssetLibraryV2Enabled };
}
