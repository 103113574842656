import { graphql } from '../../__graphqlTypes';

export const USER_UNREAD_NOTIFICATIONS = graphql(`
    query UserUnreadNotifications {
        me {
            id
            unreadNotifications {
                edges {
                    node {
                        ...NotificationFragment
                    }
                }
            }
        }
    }
`);
