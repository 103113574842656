import { MouseEvent, useCallback, useState } from 'react';
import {
    LeftNavigationTopBarNotifications,
    LeftNavigationTopBarNotificationsProps,
} from './LeftNavigationTopBarNotifications';

export type LeftNavigationTopBarNotificationsContainerProps = Omit<
    LeftNavigationTopBarNotificationsProps,
    'anchorEl' | 'onClickClose' | 'onClickOpen' | 'open'
>;

export const LeftNavigationTopBarNotificationsContainer = (
    props: LeftNavigationTopBarNotificationsContainerProps,
): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const onClickOpen = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const onClickClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <LeftNavigationTopBarNotifications
            {...props}
            anchorEl={anchorEl}
            open={open}
            onClickClose={onClickClose}
            onClickOpen={onClickOpen}
        />
    );
};

LeftNavigationTopBarNotificationsContainer.displayName = 'LeftNavigationTopBarNotificationsContainer';
