import { extendSx } from '@deltasierra/react/theme';
// eslint-disable-next-line no-restricted-imports
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

type NavigationUnderlineProps = {
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export default function NavigationUnderline({ sx, ...props }: NavigationUnderlineProps): JSX.Element {
    return (
        <Box
            {...props}
            component="span"
            sx={extendSx(
                (theme: Theme) => ({
                    '[data-active="true"] &, *:hover > &': {
                        textDecorationColor: theme.palette.error.main,
                    },
                    textDecorationColor: 'transparent',
                    textDecorationLine: 'underline',
                    textDecorationThickness: '2px',
                    textUnderlineOffset: '8px',
                }),
                sx,
            )}
        />
    );
}

NavigationUnderline.displayName = 'NavigationUnderline';
