'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
    CLIENT_FEATURES_FRAGMENT,
    ClientFeaturesFragmentFragment,
    GET_CLIENT_FEATURE_FLAGS,
    GetClientFeatureFlagsQuery,
    GetClientFeatureFlagsQueryVariables,
    getFragmentData,
} from '@deltasierra/frontend/graphql';

export function useClientFeatures(clientId: string | null): {
    clientFeatureFlags: ClientFeaturesFragmentFragment | null;
} {
    const { data } = useSuspenseQuery<GetClientFeatureFlagsQuery, GetClientFeatureFlagsQueryVariables>(
        GET_CLIENT_FEATURE_FLAGS,
        clientId ? { fetchPolicy: 'cache-and-network', variables: { clientId } } : skipToken,
    );

    return { clientFeatureFlags: getFragmentData(CLIENT_FEATURES_FRAGMENT, data?.client?.features) ?? null };
}
