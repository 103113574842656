import { useState, MouseEvent, useCallback } from 'react';
import { LeftNavigationTopBarProfile, LeftNavigationTopBarProfileProps } from './LeftNavigationTopBarProfile';

export type LeftNavigationTopBarProfileContainerProps = Omit<
    LeftNavigationTopBarProfileProps,
    'anchorEl' | 'onClickClose' | 'onClickOpen' | 'open'
>;

export const LeftNavigationTopBarProfileContainer = (props: LeftNavigationTopBarProfileContainerProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const onClickOpen = useCallback((event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    const onClickClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <LeftNavigationTopBarProfile
            {...props}
            anchorEl={anchorEl}
            open={open}
            onClickClose={onClickClose}
            onClickOpen={onClickOpen}
        />
    );
};

LeftNavigationTopBarProfileContainer.displayName = 'LeftNavigationTopBarProfileContainer';
