/* eslint-disable jsdoc/require-jsdoc */
'use client';

import { ReactNode } from 'react';
import { LocationClientContext } from '../../contexts';
import { useAppWebRouter, useUserAppContext, useValidateLocationURLParam } from '../../hooks';

type LocationClientContextProviderProps = {
    children: ReactNode;
};

export function LocationClientContextProvider({ children }: LocationClientContextProviderProps): JSX.Element {
    const appWebRouter = useAppWebRouter();
    const redirectUrl = appWebRouter.get('/');
    const data = useUserAppContext(redirectUrl);
    useValidateLocationURLParam(redirectUrl);

    return <LocationClientContext.Provider value={data}>{children}</LocationClientContext.Provider>;
}

LocationClientContextProvider.displayName = 'LocationClientContextProvider';
