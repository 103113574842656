import { Dispatch } from 'react';
import { useImmerReducer } from 'use-immer';
import { handleAssetLibraryBrowseStateActions } from '../state';
import { AssetLibraryBrowseState, AssetLibraryBrowseStateActions } from '../types';

const initialState: AssetLibraryBrowseState = {
    activeCollectionId: null,
    currentSelectedFolder: null,
    folderHistory: [],
    openModalCollectionIds: [],
};

export function useAssetLibraryBrowseState(): [
    state: AssetLibraryBrowseState,
    dispatch: Dispatch<AssetLibraryBrowseStateActions>,
] {
    const [state, dispatch] = useImmerReducer<AssetLibraryBrowseState, AssetLibraryBrowseStateActions>(
        (draft, action) => handleAssetLibraryBrowseStateActions(draft, action, initialState),
        initialState,
    );

    return [state, dispatch];
}
