import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

export type AssetEditTransformProps = {
    t: Translations<'AssetEdit'>;
};

export const AssetEditTransform = (_props: AssetEditTransformProps): JSX.Element => (
    <Box>
        {/* TODO: Zoom & Rotation - DSL-4906 & DSL-4907 */}
        {'Zoom'}
        {'Rotate'}
    </Box>
);

AssetEditTransform.displayName = 'AssetEditTransform';
