var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"JD6RrqDrvbXOdVLhVyvtS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const integrations: Parameters<typeof Sentry.init>[0]['integrations'] = [Sentry.browserTracingIntegration()];

if (BUILD_ID !== 'development') {
    integrations.push(Sentry.captureConsoleIntegration());
}

Sentry.init({
    beforeSend: (event, hint) => {
        const error = hint.originalException;
        if (typeof error === 'object' && error && 'ignoreSentry' in error && error.ignoreSentry) {
            return null;
        }
        return event;
    },
    dsn: 'https://693a1b7826f239091b8364db504d9bb7@o87323.ingest.us.sentry.io/4507332535582720',
    environment: BUILD_ID === 'development' ? 'development' : 'production',
    integrations,
    /*
     * Percentage of Sentry traces to sample and capture.
     * @see https://docs.sentry.io/product/sentry-basics/tracing/distributed-tracing/#data-sampling
     */
    tracesSampleRate: 0.01,
});
