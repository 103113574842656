import { GetGlobalDataQuery } from '@deltasierra/frontend/graphql';
import { AppWebRouterType } from '@deltasierra/frontend/routing/app-web';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useCallback, useState } from 'react';
import { LeftNavigationProps } from '../LeftNavigation';
import { useLeftNavigationAccount } from './useLeftNavigationAccount';
import { useLeftNavigationLeft } from './useLeftNavigationLeft';
import { useLeftNavigationNotifications } from './useLeftNavigationNotifications';

export type UseLeftNavigationProps = AlertContextType & {
    AppWebRouter: AppWebRouterType;
    clientId: string | null;
    isAdmin: boolean;
    locationId: string | null;
    me: GetGlobalDataQuery['me'];
    t: Translations<'Navigation'>;
};

export function useLeftNavigation({
    AppWebRouter,
    clientId,
    isAdmin,
    locationId,
    me,
    showAlert,
    t,
}: UseLeftNavigationProps): Omit<LeftNavigationProps, 'children' | 'logoImageSrc' | 't'> {
    const [isOpenDrawer, setIsOpenDrawer] = useState(true);

    const { navigation } = useLeftNavigationLeft(AppWebRouter, t, clientId, locationId);
    const { isLoadingNotifications, notifications, onClickDismissAllNotifications, onClickDismissNotification } =
        useLeftNavigationNotifications({
            showAlert,
            t,
        });

    const onClickHamburger = useCallback(() => setIsOpenDrawer(prev => !prev), []);

    const { profile, user } = useLeftNavigationAccount(AppWebRouter, me, isAdmin, t);

    return {
        helpUrl: AppWebRouter.get('/trythis'),
        homeHref: locationId ? `/dashboard/?l=${locationId}` : AppWebRouter.get('/'),
        isLoadingNotifications,
        isOpenDrawer,
        navigation,
        notificationUrl: AppWebRouter.get('/notifications'),
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
        onClickHamburger,
        profile,
        user,
    };
}
