import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

export type AssetEditCanvasProps = {
    t: Translations<'AssetEdit'>;
};

export const AssetEditCanvas = (_props: AssetEditCanvasProps): JSX.Element => (
    <Box>
        {/* TODO: Actions - DSL-4911 */}
        {'Canvas'}
    </Box>
);

AssetEditCanvas.displayName = 'AssetEditCanvas';
