import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { Box, ModalFullPage, Typography } from '@deltasierra/react/components/core';
import { AssetEditContainer } from '@deltasierra/react/features/asset-edit';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { AssetLibraryBrowser, AssetLibraryBrowserProps } from './AssetLibraryBrowser';
import { AssetLibraryHeader } from './AssetLibraryHeader';
import { AssetLibrarySearchAndSort, AssetLibrarySearchAndSortProps } from './AssetLibrarySearchAndSort';
import { AssetLibraryTabs, AssetLibraryTabsProps } from './AssetLibraryTabs';
import { AssetLibraryUploadContainer, AssetLibraryUploadContainerProps } from './AssetLibraryUploadContainer';
import { GALLERY_STATE } from './enums';
import { ResultAssetFile } from './types';

export const TAB_BROWSER = 0;
export const TAB_UPLOAD = 1;

export type AssetLibraryProps = AssetLibraryBrowserProps &
    AssetLibraryUploadContainerProps &
    Omit<AssetLibrarySearchAndSortProps, 'onClickClose'> &
    Pick<AssetLibraryTabsProps, 'currentTabIndex' | 'onChangeTabs'> & {
        disableUploadsTab?: boolean;
        onClickClose: (T: ResultAssetFile | null) => void;
        selectedAsset: AssetFileFragmentFragment | null;
    };

// eslint-disable-next-line max-lines-per-function
export const AssetLibrary = ({
    allowedTypes,
    assets,
    collections,
    currentCollection,
    currentFolders,
    currentTabIndex,
    disableUploadsTab,
    folderActions,
    galleryState,
    isCheckedConfirmRead,
    isCheckedNeverAgain,
    isLoadingAssets,
    isLoadingCollections,
    isLoadingUpload,
    isOpenCollection,
    isOpenPermissionModal,
    maxFileSize,
    onChangeAssets,
    onChangeConfirmRead,
    onChangeNeverAgain,
    onChangeSearch,
    onChangeTabs,
    onClickBreadcrumb,
    onClickClose,
    onClickHeader,
    onClickSearch,
    onClickSelectCollection,
    onClickSortBy,
    onClickThumbnail,
    onClickUploadCancel,
    onClickUploadNext,
    onClickView,
    search,
    selectedAsset,
    sortByOptions,
    t,
    viewOptions,
    watchElementRef,
}: AssetLibraryProps): JSX.Element => (
    <ModalFullPage open onClose={() => onClickClose(null)}>
        <Box
            sx={{
                backgroundColor: 'grey.100',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
                minWidth: '100%',
            }}
        >
            {galleryState === GALLERY_STATE.BASE ? (
                <>
                    <AssetLibraryHeader
                        content={
                            <AssetLibrarySearchAndSort
                                search={search}
                                sortByOptions={sortByOptions}
                                t={t}
                                viewOptions={viewOptions}
                                onChangeSearch={onChangeSearch}
                                onClickSearch={onClickSearch}
                                onClickSortBy={onClickSortBy}
                                onClickView={onClickView}
                            />
                        }
                        t={t}
                        onClickClose={() => onClickClose(null)}
                    />
                    <AssetLibraryTabs
                        currentTabIndex={currentTabIndex}
                        tabs={[
                            {
                                tabPanel: (
                                    <AssetLibraryBrowser
                                        allowedTypes={allowedTypes}
                                        assets={assets}
                                        collections={collections}
                                        currentCollection={currentCollection}
                                        currentFolders={currentFolders}
                                        folderActions={folderActions}
                                        galleryState={galleryState}
                                        isLoadingAssets={isLoadingAssets}
                                        isLoadingCollections={isLoadingCollections}
                                        isOpenCollection={isOpenCollection}
                                        maxFileSize={maxFileSize}
                                        t={t}
                                        watchElementRef={watchElementRef}
                                        onClickBreadcrumb={onClickBreadcrumb}
                                        onClickHeader={onClickHeader}
                                        onClickSelectCollection={onClickSelectCollection}
                                        onClickThumbnail={onClickThumbnail}
                                    />
                                ),
                                tabText: t('Asset Library'),
                            },
                            disableUploadsTab
                                ? null
                                : {
                                      tabPanel: (
                                          <AssetLibraryUploadContainer
                                              isCheckedConfirmRead={isCheckedConfirmRead}
                                              isCheckedNeverAgain={isCheckedNeverAgain}
                                              isLoadingUpload={isLoadingUpload}
                                              isOpenPermissionModal={isOpenPermissionModal}
                                              t={t}
                                              onChangeAssets={onChangeAssets}
                                              onChangeConfirmRead={onChangeConfirmRead}
                                              onChangeNeverAgain={onChangeNeverAgain}
                                              onClickUploadCancel={onClickUploadCancel}
                                              onClickUploadNext={onClickUploadNext}
                                          />
                                      ),
                                      tabText: t('Upload'),
                                  },
                            // Do not show the Upload Tab if disableUploadsTab is set to true
                        ].filter(isNotNullOrUndefined)}
                        onChangeTabs={onChangeTabs}
                    />
                </>
            ) : (
                <>
                    <AssetLibraryHeader
                        content={<Typography variant="h2">{t('Crop image')}</Typography>}
                        t={t}
                        onClickClose={() => onClickClose(null)}
                    />
                    {selectedAsset && <AssetEditContainer asset={selectedAsset} />}
                </>
            )}
        </Box>
    </ModalFullPage>
);

AssetLibrary.displayName = 'AssetLibrary';
