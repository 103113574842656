import { captureException } from '@sentry/browser';

export function useClipboard(): {
    copyToClipboard: (text: string) => Promise<void>;
} {
    const copyToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (error) {
            captureException(error);
            throw new Error('Navigator clipboard not available');
        }
    };

    return {
        copyToClipboard,
    };
}
