import { useCallback, useState } from 'react';
import { AssetLibraryThumbnailFolder, AssetLibraryThumbnailFolderProps } from './AssetLibraryThumbnailFolder';

export type AssetLibraryThumbnailFolderContainerProps = Pick<
    AssetLibraryThumbnailFolderProps,
    'asset' | 'dimensions' | 'folderActions' | 'onClickThumbnail' | 't'
>;

export const AssetLibraryThumbnailFolderContainer = ({
    asset,
    dimensions,
    folderActions,
    onClickThumbnail,
    t,
}: AssetLibraryThumbnailFolderContainerProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [isOpenActions, setIsOpenActions] = useState(false);

    const popperRef = useCallback((node: HTMLImageElement | null) => {
        setAnchorEl(node);
    }, []);
    return (
        <AssetLibraryThumbnailFolder
            anchorEl={anchorEl}
            asset={asset}
            dimensions={dimensions}
            folderActions={folderActions}
            isOpenActions={isOpenActions}
            popperRef={popperRef}
            t={t}
            onClickThumbnail={onClickThumbnail}
            onToggleActions={isOpen => setIsOpenActions(prev => isOpen ?? !prev)}
        />
    );
};

AssetLibraryThumbnailFolderContainer.displayName = 'AssetLibraryThumbnailFolderContainer';
