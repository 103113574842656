'use client';

import { useLazyQuery } from '@apollo/client';
import {
    ASSET_FILE_FRAGMENT,
    ASSET_FOLDER_FRAGMENT,
    AssetFileFragmentFragment,
    AssetFolderFragmentFragment,
    GET_ASSETS_ON_COLLECTION,
    GET_ASSETS_ON_FOLDER,
    GetAssetsOnCollectionQuery,
    GetAssetsOnCollectionQueryVariables,
    GetAssetsOnFolderQuery,
    GetAssetsOnFolderQueryVariables,
    getFragmentData,
    relayConnectionToArray,
    useConnectionFetchMore,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useCallback, useEffect, useMemo } from 'react';
import { ModalAsset } from '../contexts';
import { AssetLibraryBrowseStateSelectedFolderOrCollection } from '../types';

const ASSETS_TO_FETCH = 30;

export type UseAssetLibraryCollectionsAndFoldersReturns = {
    assets: ModalAsset[];
    handleFetchMoreAssets: () => void;
    hasMoreAssets: boolean;
    isLoadingAssets: boolean;
};

type UseAssetLibraryCollectionOrFolderAssetsOptions = {
    currentSelectedFolder: AssetLibraryBrowseStateSelectedFolderOrCollection | null;
    searchInputValue: string;
    showAlert: AlertContextType['showAlert'];
    t: Translations<'AssetLibrary'>;
};

// eslint-disable-next-line jsdoc/require-jsdoc, max-lines-per-function
export function useAssetLibraryCollectionOrFolderAssets({
    currentSelectedFolder: currentSelectedFolderOrCollection,
    searchInputValue,
    showAlert,
    t,
}: UseAssetLibraryCollectionOrFolderAssetsOptions): UseAssetLibraryCollectionsAndFoldersReturns {
    const [
        getCollectionAssets,
        { data: dataCollectionAssets, fetchMore: fetchMoreCollectionAssets, loading: isLoadingCollectionAssets },
    ] = useLazyQuery<GetAssetsOnCollectionQuery, GetAssetsOnCollectionQueryVariables>(GET_ASSETS_ON_COLLECTION, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            if (data.collection.__typename !== 'Collection') {
                showAlert('error', t('Assets cannot be loaded'), t('Please try again'), data.collection);
            }
        },
        onError: error => showAlert('error', t('Assets cannot be loaded'), t('Please try again'), error),
    });
    const [
        getFolderAssets,
        { data: dataFolderAssets, fetchMore: fetchMoreFolderAssets, loading: isLoadingFolderAssets },
    ] = useLazyQuery<GetAssetsOnFolderQuery, GetAssetsOnFolderQueryVariables>(GET_ASSETS_ON_FOLDER, {
        fetchPolicy: 'network-only',
        onCompleted: data =>
            data.asset.__typename !== 'AssetFolder' &&
            showAlert('error', t('Assets cannot be loaded'), t('Please try again'), data.asset),
        onError: error => showAlert('error', t('Assets cannot be loaded'), t('Please try again'), error),
    });

    const [handleFetchMoreCollectionAssets, hasMoreCollectionAssets] = useConnectionFetchMore(
        dataCollectionAssets?.collection.__typename === 'Collection' ? dataCollectionAssets.collection.assets : null,
        async after =>
            fetchMoreCollectionAssets({
                variables: { after },
            }),
    );
    const [handleFetchMoreFolderAssets, hasMoreFolderAssets] = useConnectionFetchMore(
        dataFolderAssets?.asset.__typename === 'AssetFolder' ? dataFolderAssets.asset.assets : null,
        async after =>
            fetchMoreFolderAssets({
                variables: { after },
            }),
    );

    const handleFetchMore = useCallback(() => {
        if (!currentSelectedFolderOrCollection) {
            return;
        }

        switch (currentSelectedFolderOrCollection.type) {
            case 'collection': {
                void handleFetchMoreCollectionAssets();
                break;
            }
            case 'folder': {
                void handleFetchMoreFolderAssets();
                break;
            }
            default:
                break;
        }
    }, [currentSelectedFolderOrCollection, handleFetchMoreCollectionAssets, handleFetchMoreFolderAssets]);

    const hasMoreAssets = useMemo(() => {
        if (!currentSelectedFolderOrCollection) {
            return false;
        }

        switch (currentSelectedFolderOrCollection.type) {
            case 'collection': {
                return hasMoreCollectionAssets;
            }
            case 'folder': {
                return hasMoreFolderAssets;
            }
            default:
                return false;
        }
    }, [currentSelectedFolderOrCollection, hasMoreCollectionAssets, hasMoreFolderAssets]);

    // This switches the assets based on the current selected item type
    const assets: ModalAsset[] = useMemo(() => {
        if (!currentSelectedFolderOrCollection) {
            return [];
        }

        let assetConnectionArray: Array<
            | ({
                  __typename: 'AssetFile';
              } & {
                  ' $fragmentRefs'?:
                      | {
                            AssetFileFragmentFragment: AssetFileFragmentFragment;
                        }
                      | undefined;
              })
            | ({
                  __typename: 'AssetFolder';
              } & {
                  ' $fragmentRefs'?:
                      | {
                            AssetFolderFragmentFragment: AssetFolderFragmentFragment;
                        }
                      | undefined;
              })
        > = [];

        switch (currentSelectedFolderOrCollection.type) {
            case 'collection': {
                if (dataCollectionAssets?.collection.__typename === 'Collection') {
                    assetConnectionArray = relayConnectionToArray(dataCollectionAssets.collection.assets);
                }
                break;
            }
            case 'folder': {
                if (dataFolderAssets?.asset.__typename === 'AssetFolder') {
                    assetConnectionArray = relayConnectionToArray(dataFolderAssets.asset.assets);
                }
                break;
            }
            default:
                break;
        }
        return assetConnectionArray.map(asset => {
            if (asset.__typename === 'AssetFile') {
                return getFragmentData(ASSET_FILE_FRAGMENT, asset);
            } else {
                return getFragmentData(ASSET_FOLDER_FRAGMENT, asset);
            }
        });
    }, [currentSelectedFolderOrCollection, dataCollectionAssets?.collection, dataFolderAssets?.asset]);

    // When the selected item changes or filters, refetch the correct assets
    useEffect(() => {
        if (!currentSelectedFolderOrCollection) {
            return;
        }

        if (currentSelectedFolderOrCollection.type === 'collection') {
            void getCollectionAssets({
                variables: {
                    filter: { search: searchInputValue },
                    first: ASSETS_TO_FETCH,
                    id: currentSelectedFolderOrCollection.collectionId,
                },
            });
        } else {
            void getFolderAssets({
                variables: {
                    filter: { search: searchInputValue },
                    first: ASSETS_TO_FETCH,
                    id: currentSelectedFolderOrCollection.folderId,
                },
            });
        }
    }, [currentSelectedFolderOrCollection, getCollectionAssets, getFolderAssets, searchInputValue]);

    return {
        assets,
        handleFetchMoreAssets: handleFetchMore,
        hasMoreAssets,
        isLoadingAssets: isLoadingFolderAssets || isLoadingCollectionAssets,
    };
}
