import { AppWebRouterType } from '@deltasierra/frontend/routing/app-web';
import { GetGlobalDataQuery } from '@deltasierra/frontend/graphql';
import { Translations } from '@deltasierra/translations/react';
import { useMemo } from 'react';
import { Profile, User } from '../../types';

export function useLeftNavigationAccount(
    AppWebRouter: AppWebRouterType,
    me: GetGlobalDataQuery['me'],
    isAdmin: boolean,
    t: Translations<'Navigation'>,
): {
    profile: Profile;
    user: User;
} {
    const user: User = useMemo(
        () => ({
            initials: `${me.firstName.trim().slice(0, 1)}${me.lastName.trim().slice(0, 1)}`,
            profilePicture: me.profilePicture?.signedUrl,
        }),
        [me],
    );
    const profile: Profile = useMemo(() => {
        const items: Profile = [];
        if (isAdmin) {
            items.push({
                icon: 'Lock01',
                id: 'account',
                title: t('Admin'),
                url: AppWebRouter.get('/admin'),
            });
        }
        items.push(
            ...([
                {
                    icon: 'UserCircle',
                    id: 'account',
                    title: t('Profile'),
                    url: AppWebRouter.get('/profile'),
                },
                {
                    icon: 'LogOut01',
                    id: 'sign-out',
                    title: t('Sign Out'),
                    url: AppWebRouter.get('/logout'),
                },
            ] as const),
        );
        return items;
    }, [isAdmin, t, AppWebRouter]);

    return { profile, user };
}
