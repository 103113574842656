'use client';

import { useQuery } from '@apollo/client';
import {
    COLLECTION_FRAGMENT,
    GET_CLIENT_ASSET_COLLECTIONS,
    GET_LOCATION_ASSET_COLLECTIONS,
    GetClientAssetCollectionsQuery,
    GetClientAssetCollectionsQueryVariables,
    GetLocationAssetCollectionsQuery,
    GetLocationAssetCollectionsQueryVariables,
    getFragmentData,
    relayConnectionToArray,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { useMemo } from 'react';
import { CollectionItem, ModalCollection } from '../types';

export type UseAssetLibraryCollectionsReturns = {
    collections: ModalCollection[];
    isLoadingCollections: boolean;
};

type UseAssetLibraryCollectionsOptions = {
    clientId: string;
    locationId: string | null;
    showAlert: AlertContextType['showAlert'];
    t: Translations<'AssetLibrary'>;
};

export function useAssetLibraryCollections({
    clientId,
    locationId,
    showAlert,
    t,
}: UseAssetLibraryCollectionsOptions): UseAssetLibraryCollectionsReturns {
    const { data: dataClientCollection, loading: isLoadingClientCollections } = useQuery<
        GetClientAssetCollectionsQuery,
        GetClientAssetCollectionsQueryVariables
    >(GET_CLIENT_ASSET_COLLECTIONS, {
        fetchPolicy: 'cache-and-network',
        onError: error => showAlert('error', t('Collections cannot be loaded'), t('Please try again'), error),
        variables: { id: clientId },
    });

    const { data: dataLocationCollection, loading: isLoadingLocationCollections } = useQuery<
        GetLocationAssetCollectionsQuery,
        GetLocationAssetCollectionsQueryVariables
    >(
        GET_LOCATION_ASSET_COLLECTIONS,
        locationId
            ? {
                  fetchPolicy: 'cache-and-network',
                  onError: error => showAlert('error', t('Collections cannot be loaded'), t('Please try again'), error),
                  variables: { locationId },
              }
            : { skip: true },
    );

    const clientModalCollection = useMemo((): ModalCollection | null => {
        if (!dataClientCollection?.client) {
            return null;
        }

        const clientCollectionItems: CollectionItem[] = relayConnectionToArray(
            dataClientCollection.client.collections,
        ).map(collection => getFragmentData(COLLECTION_FRAGMENT, collection));

        return {
            data: clientCollectionItems,
            id: dataClientCollection.client.id,
            title: dataClientCollection.client.title,
        };
    }, [dataClientCollection?.client]);

    const locationModalCollection = useMemo((): ModalCollection | null => {
        if (!dataLocationCollection?.location) {
            return null;
        }

        const locationCollectionItems: CollectionItem[] = relayConnectionToArray(
            dataLocationCollection.location.collections,
        ).map(collection => getFragmentData(COLLECTION_FRAGMENT, collection));
        return {
            data: locationCollectionItems,
            id: dataLocationCollection.location.id,
            title: dataLocationCollection.location.title,
        };
    }, [dataLocationCollection?.location]);

    const collections: ModalCollection[] = useMemo(
        () => [clientModalCollection, locationModalCollection].filter(isNotNullOrUndefined),
        [clientModalCollection, locationModalCollection],
    );

    return {
        collections,
        isLoadingCollections: isLoadingLocationCollections || isLoadingClientCollections,
    };
}
