import { AppWebRouterType } from '@deltasierra/frontend/routing/app-web';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import { useUnifiedFeatureFlags } from '@deltasierra/react/hooks/feature-flags';
import { Translations } from '@deltasierra/translations/react';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { Navigation } from '../../types';

// eslint-disable-next-line max-lines-per-function
export function useLeftNavigationLeft(
    AppWebRouter: AppWebRouterType,
    t: Translations<'Navigation'>,
    clientId: string | null,
    locationId: string | null,
): { navigation: Navigation } {
    const { enableNewGalleryPage } = useUnifiedFeatureFlags({
        clientId,
        featureFlags: ['enableNewGalleryPage'],
        locationId,
    });
    const currentRoutePathName = usePathname();

    const navigation: Navigation = useMemo(
        // eslint-disable-next-line max-lines-per-function
        () => {
            const leftNavigator: Navigation = [
                {
                    id: 'SECTION 1',
                    isMaintainedOnClose: true,
                    items: [
                        {
                            icon: 'GraduationHat02',
                            id: 'Learn',
                            navigationUrl: AppWebRouter.get('/trythis'),
                            title: t('Learn'),
                        },
                        {
                            icon: 'Calendar',
                            id: 'Plan',
                            navigationUrl: AppWebRouter.get('/planner'),
                            title: t('Plan'),
                        },
                        {
                            icon: 'Image01',
                            id: 'Templates',
                            isDisabled: !clientId || !locationId,
                            navigationUrl: enableNewGalleryPage
                                ? `/templates/?${QueryParameter.ClientId}=${clientId}&${
                                      QueryParameter.LocationId
                                  }=${locationId}`
                                : AppWebRouter.get('/builderTemplateGallery'),
                            title: t('Templates'),
                        },
                        {
                            icon: 'Briefcase01',
                            id: 'Assets',
                            navigationUrl: AppWebRouter.get('/assets/view'),
                            title: t('Assets'),
                        },
                        {
                            icon: 'PieChart01',
                            id: 'Report',
                            isDisabled: !clientId,
                            navigationUrl: `/report/templates/?${QueryParameter.ClientId}=${clientId}`,
                            title: t('Report'),
                        },
                        {
                            icon: 'Ticket01',
                            id: 'Special requests',
                            navigationUrl: AppWebRouter.get('/specialRequests'),
                            title: t('Special Requests'),
                        },
                    ],
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Brand'),
                    id: 'SECTION 2',
                    isMaintainedOnClose: false,
                    items: [
                        {
                            icon: 'LineChartUp01',
                            id: 'Reports',
                            navigationUrl: AppWebRouter.get('/reports'),
                            title: t('Reports'),
                        },
                        {
                            icon: 'Send01',
                            id: 'Communicate',
                            // TODO: Confirm url
                            navigationUrl: AppWebRouter.get('/brand/communicate/send'),
                            title: t('Communicate'),
                        },
                        {
                            icon: 'Settings02',
                            id: 'Manage',
                            // TODO: Confirm url
                            navigationUrl: AppWebRouter.get('/brand'),
                            title: 'Manage',
                        },
                    ],
                },
                {
                    hasDividerAbove: true,
                    headerTitle: t('Settings'),
                    id: 'SECTION 3',
                    isMaintainedOnClose: false,
                    items: [
                        {
                            icon: 'Tool01',
                            id: 'Connect',
                            navigationUrl: AppWebRouter.get('/connect'),
                            title: t('Connect'),
                        },
                        {
                            icon: 'MarkerPin01',
                            id: 'Location',
                            navigationUrl: AppWebRouter.get('/locationDetails'),
                            subItems: [
                                {
                                    id: 'Hashtags',
                                    navigationUrl: AppWebRouter.get('/location-hashtag-sets'),
                                    title: t('Hashtags'),
                                },
                                {
                                    id: 'Details',
                                    navigationUrl: AppWebRouter.get('/locationDetails'),
                                    title: t('Details'),
                                },
                            ],
                            title: t('Location'),
                        },
                    ],
                },
                {
                    hasDividerAbove: true,
                    id: 'SECTION 4',
                    isMaintainedOnClose: false,
                    items: [
                        {
                            icon: 'UserCircle',
                            id: 'Account',
                            navigationUrl: AppWebRouter.get('/profile'),
                            title: t('Account'),
                        },
                    ],
                },
            ];
            return leftNavigator.map(section => ({
                ...section,
                items: section.items.map(item => ({
                    ...item,
                    isSelected: item.navigationUrl.startsWith(currentRoutePathName),
                    subitems: item.subItems?.map(subItem => ({
                        ...subItem,
                        isSelected: subItem.navigationUrl.startsWith(currentRoutePathName),
                    })),
                })),
            }));
        },
        [AppWebRouter, clientId, currentRoutePathName, enableNewGalleryPage, locationId, t],
    );

    return { navigation };
}
