import { graphql } from '../../__graphqlTypes';

export const CLIENT_FEATURES_FRAGMENT = graphql(`
    fragment ClientFeaturesFragment on ClientFeatures {
        dashboardV2
        enableCropResizeAssetLibraryV2
        enableEmailTemplateDivider
        enableEmailTemplatePlaceholder
        enableEmailTemplateSpacer
        enableNewGalleryPage
        isAssetLibraryV2Enabled
        showOldEmailTemplatesOption
        socialMergeFieldsFrontend
        socialReportingTemplates
        templateBuilder
        templateGallery
    }
`);
