import { useTranslations } from '@deltasierra/translations/react';
import { AssetEdit, AssetEditProps } from './AssetEdit';

export type AssetEditContainerProps = Omit<AssetEditProps, 't'>;

export const AssetEditContainer = (props: AssetEditContainerProps): JSX.Element => {
    const t = useTranslations('AssetEdit');
    return <AssetEdit {...props} t={t} />;
};

AssetEditContainer.displayName = 'AssetEditContainer';
