import { Avatar, Chip, ChipProps } from '@mui/material';
import { Tooltip } from './Tooltip';

export type ChipExtendedProps = ChipProps & {
    count?: number;
    tooltip?: string;
};

export function ChipExtended({ count, tooltip, ...props }: ChipExtendedProps): JSX.Element {
    const isPrimary = props.color === 'primary';
    return (
        <Tooltip title={tooltip}>
            <span role="tooltip">
                <Chip
                    avatar={
                        count ? (
                            <Avatar
                                sx={{
                                    bgcolor: isPrimary ? 'rgba(255, 255, 255, 0.7) !important' : undefined,
                                    color: isPrimary ? theme => `${theme.palette.primary.main} !important` : undefined,
                                    fontWeight: 'bold',
                                    height: '18px !important',
                                    width: '18px !important',
                                }}
                            >
                                {count}
                            </Avatar>
                        ) : undefined
                    }
                    {...props}
                    sx={props.sx}
                />
            </span>
        </Tooltip>
    );
}
ChipExtended.displayName = 'ChipExtended';
