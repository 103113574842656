import { Draft } from 'immer';
import { AssetLibraryBrowseState, ActionClickBreadcrumb } from '../../types';

export function handleActionClickBreadcrumb(
    state: Draft<AssetLibraryBrowseState>,
    action: ActionClickBreadcrumb,
): void {
    // If no folder, must be a collection click
    if (!action.folderId) {
        // Clear the history
        state.folderHistory = [];
        // Select the current collection
        if (state.activeCollectionId) {
            state.currentSelectedFolder = {
                collectionId: state.activeCollectionId,
                type: 'collection',
            };
        }
    } else {
        // Find the index in the breadcrumbs and remove its children
        const newFolderIndex = state.folderHistory.findIndex(folder => folder.id === action.folderId);
        if (newFolderIndex >= 0) {
            state.folderHistory = state.folderHistory.slice(0, newFolderIndex + 1);
        }
        // Select the folder
        state.currentSelectedFolder = {
            folderId: action.folderId,
            type: 'folder',
        };
    }
}
