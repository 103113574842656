import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import { Box, Stack } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { AssetEditFooter } from './AssetEditFooter';
import { AssetEditTransform } from './AssetEditTransform';
import { AssetEditActions } from './AssetEditActions';
import { AssetEditCanvas } from './AssetEditCanvas';

export type AssetEditProps = {
    asset: AssetFileFragmentFragment;
    t: Translations<'AssetEdit'>;
};

const mediumGap = '1.5rem';
const largeGap = '2.5rem';

export const AssetEdit = ({ t }: AssetEditProps): JSX.Element => (
    <Stack
        sx={{
            columnGap: mediumGap,
            maxHeight: '100%',
            minHeight: '100%',
            padding: mediumGap,
            paddingLeft: largeGap,
        }}
    >
        <Box
            sx={{
                columnGap: '3rem',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                minHeight: '7.5rem',
                width: '100%',
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <AssetEditCanvas t={t} />
            </Box>
            <Box
                sx={{
                    minWidth: '160px',
                }}
            >
                <AssetEditActions t={t} />
            </Box>
        </Box>

        <Stack>
            <AssetEditTransform t={t} />
            <AssetEditFooter t={t} onClickCancel={() => null} onClickCrop={() => null} />
        </Stack>
    </Stack>
);

AssetEdit.displayName = 'AssetEdit';
