import { graphql } from '../../__graphqlTypes';

export const MARK_USER_ALL_NOTIFICATIONS_READ = graphql(`
    mutation MarkReadAllNotifications {
        markReadAllNotifications {
            ... on MarkReadSuccess {
                numberMarkedRead
            }
            ... on MarkReadError {
                code
                message
            }
        }
    }
`);
