import { graphql } from '../../__graphqlTypes';

export const VALIDATE_LOCATION = graphql(`
    query ValidateLocation($id: ID!) {
        location(id: $id) {
            id
            legacyId
        }
    }
`);
