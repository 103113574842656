'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
    VALIDATE_LOCATION,
    ValidateLocationQuery,
    ValidateLocationQueryVariables,
} from '@deltasierra/frontend/graphql';
import { QueryParameter } from '@deltasierra/frontend/routing/core';
import { setCookie } from 'cookies-next';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useAppConfig } from './useAppConfig';

const COOKIE_KEY = 'plannerLocation';

/**
 * Validates the location in the current URL params and then updates the cookie
 *
 * @param redirectUrl - on incorrect location id
 */
export function useValidateLocationURLParam(redirectUrl: string): void {
    const searchParams = useSearchParams();
    const { appWebUrl } = useAppConfig();

    const locationIdParams = searchParams.get(QueryParameter.LocationId);
    const { data } = useSuspenseQuery<ValidateLocationQuery, ValidateLocationQueryVariables>(
        VALIDATE_LOCATION,
        locationIdParams
            ? {
                  variables: { id: locationIdParams },
              }
            : skipToken,
    );

    useEffect(() => {
        if (!locationIdParams) {
            // There is no location param in this url so don't need to validate
            return;
        }
        if (data && data.location) {
            const baseUrlWithoutProtocol = new URL(appWebUrl).hostname;
            // There is a valid location, update the cookie
            setCookie(COOKIE_KEY, `${data.location.legacyId}`, {
                domain: `.${baseUrlWithoutProtocol}`,
                path: '/',
                secure: true,
            });
        } else {
            // The location is not valid, redirect
            window.location.replace(redirectUrl);
        }
    }, [appWebUrl, data, locationIdParams, redirectUrl]);
}
