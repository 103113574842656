/* eslint-disable @typescript-eslint/prefer-reduce-type-parameter, jsdoc/require-param*/
import { useLocationFeatures } from './useLocationFeatures';
import { useClientFeatures } from './useClientFeatures';
import { useUserFeatures } from './useUserFeatures';

type FeatureFlagsIntersection =
    | Exclude<ReturnType<typeof useClientFeatures>['clientFeatureFlags'], null>
    | Exclude<ReturnType<typeof useLocationFeatures>['locationFeatureFlags'], null>
    | Exclude<ReturnType<typeof useUserFeatures>['userFeatureFlags'], null>;

export type FeatureFlag = Exclude<keyof FeatureFlagsIntersection, ' $fragmentName' | '__typename'>;

function extractFeatureFlag(flag: FeatureFlag, node: FeatureFlagsIntersection | null): boolean | null {
    if (node === null) {
        return null;
    }
    return flag in node ? node[flag] : null;
}

/**
 *
 *  Feature flag must be the same across client, location and user fragments
 */
export function useUnifiedFeatureFlags<T extends FeatureFlag[]>({
    clientId,
    featureFlags,
    locationId,
}: {
    clientId: string | null;
    featureFlags: T;
    locationId: string | null;
}): Record<T[number], boolean> {
    const { clientFeatureFlags } = useClientFeatures(clientId);
    const { locationFeatureFlags } = useLocationFeatures(locationId);
    const { userFeatureFlags } = useUserFeatures();

    return featureFlags.reduce(
        (acc, next) => ({
            ...acc,
            [next]:
                extractFeatureFlag(next, userFeatureFlags) ??
                extractFeatureFlag(next, locationFeatureFlags) ??
                extractFeatureFlag(next, clientFeatureFlags) ??
                false,
        }),
        {} as Record<T[number], boolean>,
    );
}
