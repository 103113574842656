import { AssetFolderFragmentFragment } from '@deltasierra/frontend/graphql';
import {
    Box,
    ClickAwayListener,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography,
} from '@deltasierra/react/components/core';
import { DotsVertical, Folder } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { Ref } from 'react';
import { FolderAction } from './types';

export type AssetLibraryThumbnailFolderProps = {
    anchorEl: HTMLDivElement | null;
    asset: AssetFolderFragmentFragment;
    dimensions: Record<'height' | 'width', number>;
    folderActions: FolderAction[];
    isOpenActions: boolean;
    onClickThumbnail: () => void;
    onToggleActions: (T?: boolean) => void;
    popperRef: Ref<HTMLImageElement>;
    t: Translations<'AssetLibrary'>;
};

// eslint-disable-next-line max-lines-per-function
export const AssetLibraryThumbnailFolder = ({
    anchorEl,
    asset,
    dimensions,
    folderActions,
    isOpenActions,
    onClickThumbnail,
    onToggleActions,
    popperRef,
    t,
}: AssetLibraryThumbnailFolderProps): JSX.Element => (
    <Box maxWidth={dimensions.width} position="relative">
        {folderActions.length > 0 ? (
            <>
                <IconButton
                    aria-label={t('Asset Library options')}
                    sx={{
                        bgcolor: 'grey.100',
                        color: 'primary.main',
                        margin: '0.3rem',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        transform: 'rotate(90deg)',
                        zIndex: 1,
                    }}
                    onClickCapture={event => {
                        event.stopPropagation();
                        onToggleActions();
                    }}
                >
                    <DotsVertical />
                </IconButton>
                <Popper
                    anchorEl={anchorEl}
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ]}
                    open={isOpenActions}
                    placement="right-start"
                    sx={{
                        minWidth: dimensions.width,
                        zIndex: 'fab',
                    }}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: 'center left',
                            }}
                        >
                            <Paper
                                sx={theme => ({
                                    boxShadow: theme.mixins.boxShadow.overlay,
                                })}
                            >
                                <ClickAwayListener onClickAway={() => onToggleActions(false)}>
                                    <MenuList id="split-button-menu">
                                        {folderActions.map(({ onClick, value }) => (
                                            <MenuItem
                                                key={value}
                                                onClick={() => {
                                                    onClick();
                                                    onToggleActions(false);
                                                }}
                                            >
                                                <Typography variant="body1">{value}</Typography>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        ) : null}
        <Tooltip title={asset.title}>
            <Box
                key={asset.id}
                ref={popperRef}
                sx={[
                    dimensions,
                    {
                        '&:hover': {
                            outlineColor: 'secondary.main',
                            outlineStyle: 'solid',
                            outlineWidth: '2px',
                        },
                        backgroundColor: 'primary.light',
                        borderRadius: '8px',
                        color: 'primary.main',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        position: 'relative',
                    },
                ]}
                onClick={onClickThumbnail}
            >
                <Box
                    sx={{
                        alignItems: 'flex-end',
                        display: 'flex',
                        height: '65%',
                        justifyContent: 'center',
                        minHeight: '65%',
                        path: { strokeWidth: 0.7 },
                    }}
                >
                    <Folder height="75%" width="75%" />
                </Box>
                <Typography
                    sx={{
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                    }}
                    variant="h4"
                >
                    {asset.title}
                </Typography>
            </Box>
        </Tooltip>
    </Box>
);

AssetLibraryThumbnailFolder.displayName = 'AssetLibraryThumbnailFolder';
