import * as Icons from '@deltasierra/react/icons';
import { SVGProps } from 'react';

export type CustomIconName = keyof typeof Icons;

export const CustomIcon = ({
    customIconName,
    ...props
}: SVGProps<SVGSVGElement> & {
    customIconName: CustomIconName;
}): JSX.Element => {
    const NamedIcon = Icons[customIconName];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!NamedIcon) {
        throw new Error('Custom icon not found');
    }

    return <NamedIcon {...(props as any)} />;
};

CustomIcon.displayName = 'CustomIcon';
