import { useMutation, useQuery } from '@apollo/client';
import {
    MARK_USER_ALL_NOTIFICATIONS_READ,
    MARK_USER_NOTIFICATION_READ,
    MarkReadAllNotificationsMutation,
    MarkReadAllNotificationsMutationVariables,
    MarkUserNotificationReadMutation,
    MarkUserNotificationReadMutationVariables,
    NOTIFICATION_FRAGMENT,
    USER_UNREAD_NOTIFICATIONS,
    UserUnreadNotificationsQuery,
    UserUnreadNotificationsQueryVariables,
    getFragmentData,
    relayConnectionToArray,
} from '@deltasierra/frontend/graphql';
import { AlertContextType } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';
import { useCallback, useEffect, useState } from 'react';
import { Notification } from '../../types';
import { LeftNavigationProps } from '../LeftNavigation';
import { mapNodesToNotifications } from '../helpers';

export type UseLeftNavigationProps = AlertContextType & {
    t: Translations<'Navigation'>;
};

export function useLeftNavigationNotifications({
    showAlert,
    t,
}: UseLeftNavigationProps): Pick<
    LeftNavigationProps,
    'isLoadingNotifications' | 'notifications' | 'onClickDismissAllNotifications' | 'onClickDismissNotification'
> {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const { data, loading, refetch } = useQuery<UserUnreadNotificationsQuery, UserUnreadNotificationsQueryVariables>(
        USER_UNREAD_NOTIFICATIONS,
    );

    const [markSingleNotificationRead] = useMutation<
        MarkUserNotificationReadMutation,
        MarkUserNotificationReadMutationVariables
    >(MARK_USER_NOTIFICATION_READ);
    const [markAllNotificationsRead] = useMutation<
        MarkReadAllNotificationsMutation,
        MarkReadAllNotificationsMutationVariables
    >(MARK_USER_ALL_NOTIFICATIONS_READ);

    useEffect(() => {
        if (!data) {
            return;
        }
        const nodes = relayConnectionToArray(data.me.unreadNotifications);
        setNotifications(mapNodesToNotifications(getFragmentData(NOTIFICATION_FRAGMENT, nodes)));
    }, [data]);

    const onClickDismissNotification = useCallback(
        async (notificationId: string) => {
            try {
                setNotifications(prev => [...prev].filter(({ id }) => id !== notificationId));
                await markSingleNotificationRead({ variables: { input: { notificationId } } });
            } catch (error) {
                showAlert('error', t('Notification could not be marked as read'), t('Please try again'), error);
                void refetch();
            }
        },
        [refetch, showAlert, t, markSingleNotificationRead],
    );
    const onClickDismissAllNotifications = useCallback(async () => {
        try {
            setNotifications([]);
            await markAllNotificationsRead();
        } catch (error) {
            showAlert('error', t('Notification could not be marked as read'), t('Please try again'), error);
            void refetch();
        }
    }, [markAllNotificationsRead, refetch, showAlert, t]);

    return {
        isLoadingNotifications: loading,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
    };
}
