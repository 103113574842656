'use client';

import { useSuspenseQuery } from '@apollo/client';
import {
    GET_USER_FEATURE_FLAGS,
    USER_FEATURES_FRAGMENT,
    UserFeaturesFragmentFragment,
    getFragmentData,
} from '@deltasierra/frontend/graphql';

export function useUserFeatures(): {
    userFeatureFlags: UserFeaturesFragmentFragment | null;
} {
    const { data } = useSuspenseQuery(GET_USER_FEATURE_FLAGS, { fetchPolicy: 'cache-and-network' });

    return { userFeatureFlags: getFragmentData(USER_FEATURES_FRAGMENT, data.me.features) };
}
