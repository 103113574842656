import { extendSx } from '@deltasierra/react/theme';
import { Box, SxProps, Theme } from '@mui/material';
import Image from 'next/image';
import { Skeleton } from './Skeleton';
import { shimmerDataImageString } from './shimmerDataImageString';

const LOGO_SIZE_PX = 30;

export type LogoCircleProps = {
    title: string;
    url?: string;
    id: string;
    sx?: SxProps<Theme>;
};

export const LogoCircle = ({ id, sx, title, url }: LogoCircleProps): JSX.Element => (
    <Box
        sx={extendSx(
            {
                borderColor: theme => theme.palette.grey[300],
                borderRadius: '100%',
                borderStyle: 'solid',
                borderWidth: '1px',
                height: LOGO_SIZE_PX,
                overflow: 'hidden',
                position: 'relative',
                width: LOGO_SIZE_PX,
            },
            sx,
        )}
    >
        {!url ? (
            <Skeleton height="100%" variant="circular" width="100%" />
        ) : (
            <Image
                alt={title}
                fill
                placeholder={shimmerDataImageString('22', id)}
                quality={100}
                sizes="auto"
                src={url}
                style={{
                    objectFit: 'contain',
                }}
                unoptimized
            />
        )}
    </Box>
);
LogoCircle.displayName = 'LogoCircle';
