import { graphql } from '../../__graphqlTypes';

export const NOTIFICATION_FRAGMENT = graphql(`
    fragment NotificationFragment on InAppNotification {
        createdAt
        id
        link
        message
        severity
        subject
        type
    }
`);
