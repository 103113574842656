'use client';

import { skipToken, useSuspenseQuery } from '@apollo/client';
import {
    GET_LOCATION_FEATURE_FLAGS_QUERY,
    getFragmentData,
    GetLocationFeatureFlagsQuery,
    GetLocationFeatureFlagsQueryVariables,
    LOCATION_FEATURES_FRAGMENT,
    LocationFeaturesFragmentFragment,
} from '@deltasierra/frontend/graphql';

export function useLocationFeatures(locationId: string | null): {
    locationFeatureFlags: LocationFeaturesFragmentFragment | null;
} {
    const { data } = useSuspenseQuery<GetLocationFeatureFlagsQuery, GetLocationFeatureFlagsQueryVariables>(
        GET_LOCATION_FEATURE_FLAGS_QUERY,
        locationId ? { fetchPolicy: 'cache-and-network', variables: { locationId } } : skipToken,
    );
    return { locationFeatureFlags: getFragmentData(LOCATION_FEATURES_FRAGMENT, data?.location?.features) ?? null };
}
