import { Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';

export type AlertProps = Pick<
    MuiAlertProps,
    'action' | 'children' | 'icon' | 'onClose' | 'severity' | 'sx' | 'variant'
>;

export const Alert = (props: AlertProps): JSX.Element => <MuiAlert {...props} />;

Alert.displayName = 'Alert';
