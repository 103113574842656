import { List, Skeleton } from '@deltasierra/react/components/core';
import { Fragment } from 'react';

export type SimpleSkeletonListProps = {
    skeleton?: number[];
};

export const SimpleSkeletonList = ({ skeleton }: SimpleSkeletonListProps): JSX.Element => (
    <>
        {skeleton?.map((header, i) => (
            <Fragment key={i}>
                <Skeleton sx={{ marginX: '10%' }} />
                <List
                    disablePadding
                    sx={{
                        marginX: '10%',
                        paddingRight: '20%',
                    }}
                >
                    {Array(header)
                        .fill('')
                        .map((_, index) => (
                            <Skeleton component="li" key={index} />
                        ))}
                </List>
            </Fragment>
        ))}
    </>
);

SimpleSkeletonList.displayName = 'SimpleSkeletonList';
