import { Box } from '@deltasierra/react/components/core';
import { Translations } from '@deltasierra/translations/react';

export type AssetEditFooterProps = {
    onClickCancel: () => void;
    onClickCrop: () => void;
    t: Translations<'AssetEdit'>;
};

export const AssetEditFooter = (_props: AssetEditFooterProps): JSX.Element => (
    <Box>
        {/* TODO: Footer - DSL-4977 */}
        {'AssetEditFooter'}
    </Box>
);

AssetEditFooter.displayName = 'AssetEditFooter';
