/* eslint-disable consistent-return */
import { assertNever } from '@deltasierra/type-utilities';
import { Draft } from 'immer';
import { ImmerReducer } from 'use-immer';
import { AssetLibraryBrowseState, AssetLibraryBrowseStateActions } from '../../types';
import { handleActionSelectCollection } from './handle-action-select-collection';
import { handleActionSelectFolder } from './handle-action-select-folder';
import { handleActionToggleCollectionList } from './handle-action-toggle-collection-list';
import { handleActionClickBreadcrumb } from './handle-action-click-breadcrumb';

export function handleAssetLibraryBrowseStateActions(
    draft: Draft<AssetLibraryBrowseState>,
    action: AssetLibraryBrowseStateActions,
    _initialState: AssetLibraryBrowseState,
): ReturnType<ImmerReducer<AssetLibraryBrowseState, AssetLibraryBrowseStateActions>> {
    switch (action.action) {
        case 'clickBreadcrumb': {
            handleActionClickBreadcrumb(draft, action);
            return;
        }
        case 'selectCollection': {
            handleActionSelectCollection(draft, action);
            return;
        }
        case 'selectFolder': {
            handleActionSelectFolder(draft, action);
            return;
        }
        case 'toggleCollectionList': {
            handleActionToggleCollectionList(draft, action);
            return;
        }
        default:
            return assertNever(action);
    }
}
