'use client';

import { useSuspenseQuery } from '@apollo/client';
import { GET_GLOBAL_DATA, GetGlobalDataQuery } from '@deltasierra/frontend/graphql';
import { ReactNode } from 'react';
import { GlobalDataContext } from '../../contexts';
import { useRegisterHotjarUser, useRegisterSentryUser } from '../../hooks';

type GlobalDataContextProviderProps = {
    children: ReactNode;
};

/**
 * This component provides the logged-in context for the application. The v2 app has no concept of non-logged-in
 * user's yet so we expect this to load first on every single route. It will help us load the information for
 * features and the user info so we can customize their experience.
 *
 * We don't have to do anything special here for the user context as it is handled at the ApolloProvider level.
 * We simply run a GraphQL request against the backend and if we aren't logged in, the ApolloProvider will catch
 * the error at the root and redirect us back to the login page on the v1 app. This means as long as this query is
 * successful we will always have logged in state provided in the context.
 */
export function GlobalDataContextProvider({ children }: GlobalDataContextProviderProps): JSX.Element {
    const { data } = useSuspenseQuery<GetGlobalDataQuery>(GET_GLOBAL_DATA);

    useRegisterHotjarUser(data);
    useRegisterSentryUser(data);

    return <GlobalDataContext.Provider value={data}>{children}</GlobalDataContext.Provider>;
}

GlobalDataContextProvider.displayName = 'GlobalDataContextProvider';
