'use client';

import { AssetFileFragmentFragment } from '@deltasierra/frontend/graphql';
import {
    AssetLibraryModalContext,
    AssetLibraryModalContextType,
    AssetLibraryUploadDestination,
    ResultAssetFile,
} from '@deltasierra/react/features/asset-library-modal';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { AssetLibraryModalContainer } from '../AssetLibraryModalContainer';

export type OnCloseModalType = (assetFile: ResultAssetFile | null) => void;

type AssetLibraryModalState = {
    clientId: string;
    disableUploadsTab: boolean;
    locationId: string | undefined;
    onAssetSelected: (asset: ResultAssetFile) => void;
    preUploadFile: File | null;
    uploadDestination: AssetLibraryUploadDestination;
};

export const AssetLibraryModalProvider = ({ children }: { children: ReactNode }): JSX.Element => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState<AssetLibraryModalState | null>(null);
    const [selectedAsset, setSelectedAsset] = useState<AssetFileFragmentFragment | null>(null);

    const openAssetModal = useCallback<AssetLibraryModalContextType['openAssetModal']>(
        ({ clientId, disableUploadsTab, file, locationId, onAssetSelected, uploadDestination }) => {
            setIsModalOpen(true);
            setState({
                clientId,
                disableUploadsTab: !!disableUploadsTab,
                locationId,
                onAssetSelected,
                preUploadFile: file ?? null,
                uploadDestination,
            });
        },
        [setIsModalOpen, setState],
    );

    const modalContextValue: AssetLibraryModalContextType = useMemo(
        () => ({
            openAssetModal,
        }),
        [openAssetModal],
    );

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);
        setState(null);
    }, []);

    const onAssetSelectedWithClose = useCallback(
        (asset: ResultAssetFile) => {
            if (state) {
                state.onAssetSelected(asset);
                onCloseModal();
            }
        },
        [state, onCloseModal],
    );

    return (
        <AssetLibraryModalContext.Provider value={modalContextValue}>
            {children}
            {isModalOpen && state && (
                <AssetLibraryModalContainer
                    clientId={state.clientId}
                    disableUploadsTab={state.disableUploadsTab}
                    locationId={state.locationId}
                    preUploadFile={state.preUploadFile}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    uploadDestination={state.uploadDestination}
                    onAssetSelected={onAssetSelectedWithClose}
                    onCloseModal={onCloseModal}
                />
            )}
        </AssetLibraryModalContext.Provider>
    );
};

AssetLibraryModalProvider.displayName = 'AssetLibraryModalProvider';
