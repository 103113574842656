import { Box, IconButton } from '@deltasierra/react/components/core';
import { XClose } from '@deltasierra/react/icons';
import { Translations } from '@deltasierra/translations/react';
import { ReactNode } from 'react';

export type AssetLibraryHeaderProps = {
    content: ReactNode;
    onClickClose: () => void;
    t: Translations<'AssetLibrary'>;
};

export const AssetLibraryHeader = ({ content, onClickClose, t }: AssetLibraryHeaderProps): JSX.Element => (
    <Box
        sx={{
            alignItems: 'center',
            columnGap: '3rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: '7.5rem',
            paddingTop: '1.5rem',
            paddingX: '1.5rem',
            width: '100%',
        }}
    >
        <IconButton
            aria-label={t('Close')}
            sx={{
                color: 'primary.main',
                position: 'absolute',
                right: '0.2rem',
                top: '0.2rem',
            }}
            onClick={onClickClose}
        >
            <XClose />
        </IconButton>
        {content}
    </Box>
);

AssetLibraryHeader.displayName = 'AssetLibraryHeader';
