import { extendSx } from '@deltasierra/react/theme';
// eslint-disable-next-line no-restricted-imports
import { Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

type NavigationListItemProps = {
    children: ReactNode;
    sx?: SxProps<Theme>;
};

export default function NavigationListItem({ sx, ...props }: NavigationListItemProps): JSX.Element {
    return <Box {...props} component="li" sx={extendSx({ position: 'relative' }, sx)} />;
}

NavigationListItem.displayName = 'NavigationListItem';
