import { extendSx } from '@deltasierra/react/theme';
// eslint-disable-next-line no-restricted-imports
import { Avatar, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import Image from 'next/image';
import { customBreakpoints } from './Navigation';
import NavigationActiveLink from './NavigationActiveLink';
import NavigationButton from './NavigationButton';
import NavigationList from './NavigationList';
import NavigationUnderline from './NavigationUnderline';
import NavigationListAccountDropdown, { NavigationListAccountDropdownProps } from './NavigationListAccountDropdown';

export type NavigationListAccountProps = NavigationListAccountDropdownProps & {
    notificationsHref: string;
    openDropdownUser: boolean;
    sx?: SxProps<Theme>;
    toggleOpenDropdownUser: () => void;
    userImageSrc?: string;
    userInitials: string;
    userName: string;
};

export default function NavigationListAccount({
    adminHref,
    agenciesHref,
    agencyHref,
    clientLinks,
    connectHref,
    homeHref,
    icons,
    locationDetailsHref,
    notificationsHref,
    openDropdownUser,
    overviewHref,
    profileHref,
    showAdmin,
    showAgencies,
    showAgency,
    showPlan,
    signOutHref,
    sx,
    t,
    toggleOpenDropdownUser,
    userDropdownRef,
    userImageSrc,
    userInitials,
    userName,
    ...props
}: NavigationListAccountProps): JSX.Element {
    return (
        <NavigationList
            {...props}
            sx={extendSx(
                {
                    display: 'flex',
                    justifySelf: 'flex-end',
                    width: 'auto',
                },
                sx,
            )}
        >
            <Box
                component="li"
                sx={(theme: Theme) => ({
                    display: 'none',
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        display: 'flex',
                    },
                })}
            >
                <NavigationButton component={NavigationActiveLink} href={notificationsHref}>
                    <Image alt={t('Notifications')} height="16" src={icons.notifications} width="16" />
                </NavigationButton>
            </Box>
            <Box
                component="li"
                sx={(theme: Theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: '-2px',
                    marginTop: '8px',
                    [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                        marginLeft: 0,
                        marginTop: 0,
                    },
                })}
            >
                <NavigationButton
                    sx={(theme: Theme) => ({
                        paddingTop: '2px',
                        [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                            marginLeft: '4px',
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                        },
                        [theme.breakpoints.up(customBreakpoints['2-lg'])]: {
                            marginLeft: '10px',
                        },
                    })}
                    title={t(openDropdownUser ? 'Close account menu' : 'Open account menu')}
                    onMouseDown={toggleOpenDropdownUser}
                >
                    <Avatar
                        alt={userName}
                        src={userImageSrc}
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'white',
                            display: 'flex',
                            fontFamily: 'tahoma, sans-serif',
                            fontSize: 14,
                            height: 32,
                            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
                            width: 32,
                        }}
                    >
                        {userInitials}
                    </Avatar>
                    <NavigationUnderline
                        sx={(theme: Theme) => ({
                            [theme.breakpoints.up(customBreakpoints['1-md'])]: {
                                display: 'none',
                            },
                        })}
                    >
                        {userName}
                    </NavigationUnderline>
                </NavigationButton>
                {openDropdownUser && (
                    <NavigationListAccountDropdown
                        adminHref={adminHref}
                        agenciesHref={agenciesHref}
                        agencyHref={agencyHref}
                        clientLinks={clientLinks}
                        connectHref={connectHref}
                        homeHref={homeHref}
                        icons={icons}
                        locationDetailsHref={locationDetailsHref}
                        overviewHref={overviewHref}
                        profileHref={profileHref}
                        showAdmin={showAdmin}
                        showAgencies={showAgencies}
                        showAgency={showAgency}
                        showPlan={showPlan}
                        signOutHref={signOutHref}
                        t={t}
                        userDropdownRef={userDropdownRef}
                    />
                )}
            </Box>
        </NavigationList>
    );
}

NavigationListAccount.displayName = 'NavigationListAccount';
