import * as React from 'react';
/**
 * Keeps a child element of a scrollable element in view
 * note: add ref to parent element, and the index is the index of the parent's children
 * If there are any intermediate elemenets, this hook will not function correctly
 *
 * @example
 * const [currentIndex, setCurrentIndex] = useState(0);
 * const parentRef = useKeepInView(currentIndex);
 *
 * <ul ref={parentRef} style={{ overflowY: 'scroll' }}>
 *   [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(index => (
 *     <li key={index} style={{ fontWeight: index === currentIndex ? '700' : '400' }}>
 *       {index}
 *     </li>
 *   ))
 * </ul>
 * <button onClick={setCurrentIndex(prev => Math.max(0, prev - 1))}>{'-'}</button>
 * <button onClick={setCurrentIndex(prev => Math.min(9, prev + 1))}>{'+'}</button>
 * @param index - the index (of the children of referenced element) to keep in view
 * @returns ref - to be added to the parent elemen
 */
export function useKeepInView<T extends HTMLElement>(index: number): React.MutableRefObject<T | null> {
    const ref = React.useRef<T | null>(null);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (ref.current && ref.current.children[index]) {
            ref.current.children[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [index]);

    return ref;
}
