/**
 * Provide shape of translation messages for type safety and autocompletion
 * ? Reference: https://next-intl-docs.vercel.app/docs/workflows/typescript
 */
import { Messages } from '@deltasierra/translations/core';
import { NamespaceKeys, NestedKeyOf, useTranslations as useTranslationsInitial } from 'next-intl';

declare global {
    interface IntlMessages extends Messages {}
}

type NeverBrand = {
    __brand: never;
};

type BrandedKey<NestedKey> = {
    __brand: NestedKey;
};

export type Translations<NestedKey extends NamespaceKeys<IntlMessages, NestedKeyOf<IntlMessages>> = never> = ReturnType<
    typeof useTranslationsInitial<NestedKey>
> & (BrandedKey<NestedKey> extends NeverBrand ? BrandedKey<'__ROOT__'> : BrandedKey<NestedKey>);


export function useTranslations<NestedKey extends NamespaceKeys<IntlMessages, NestedKeyOf<IntlMessages>> = never>(
    namespace?: NestedKey,
): Translations<NestedKey> {
    return useTranslationsInitial(namespace) as unknown as Translations<NestedKey>;
}
