import { graphql } from '../../__graphqlTypes';

export const GET_LOCATION_FEATURE_FLAGS_QUERY = graphql(`
    query GetLocationFeatureFlags($locationId: ID!) {
        location(id: $locationId) {
            id
            features {
                ...LocationFeaturesFragment
            }
        }
    }
`);
