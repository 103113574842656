import { NotificationFragmentFragment } from '@deltasierra/frontend/graphql';
import { AlertColor, CustomIconName } from '@deltasierra/react/components/core';
import { assertNever } from '@deltasierra/type-utilities';
import { DateTime } from 'luxon';
import { InAppNotificationSeverity, InAppNotificationType, Notification } from '../../types';

function getIcon(type: InAppNotificationType): CustomIconName | null {
    switch (type) {
        case 'ImagePublishFailed':
            return 'Image01';
        case 'VideoPublishedLocally':
        case 'VideoPublishedExternally':
        case 'VideoPublishFailed':
            return 'VideoRecorder';
        case 'MapGenerated':
            return 'MarkerPin01';
        case 'TestInAppNotifications':
        case 'ScheduledPost.NoNotifiableDevices':
            return null;
        case 'Agency.CustomMessage':
            return 'Announcement02';
        case 'EmailPublishSuccess':
        case 'EmailPublishFailed':
            return 'Mail02';
        default:
            return assertNever(type);
    }
}

function getSeverity(severity: InAppNotificationSeverity): AlertColor {
    switch (severity) {
        case 'danger':
            return 'error';
        case 'success':
        case 'info':
            return 'info';
        case 'warning':
            return 'warning';
        default:
            throw assertNever(severity);
    }
}

export function mapNodesToNotifications(notificationNode: ReadonlyArray<NotificationFragmentFragment>): Notification[] {
    return notificationNode.map(node => ({
        icon: getIcon(node.type as InAppNotificationType),
        id: node.id,
        link: node.link ?? null,
        severity: getSeverity(node.severity as InAppNotificationSeverity),
        timestamp: node.createdAt ? DateTime.fromISO(node.createdAt) : null,
        title: node.subject ?? node.message ?? '',
    }));
}
