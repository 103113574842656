import { graphql } from '../../__graphqlTypes';

export const GET_LEGACY_LOCATION = graphql(`
    query GetLegacyLocation($legacyLocationId: Float!) {
        legacyLocation(legacyLocationId: $legacyLocationId) {
            id
            client {
                id
            }
        }
    }
`);
